import React from "react"
import logoImage from "../images/logo-pensjonatu-white.svg"
import logoRound from "../images/logo-wierzba-small.png"
import logoStyles from './logo.module.scss'

class Logo extends React.Component {
    render() {
      return (
        <div className={`animate__animated animate__fadeIn ${logoStyles.LogoWrapper}`}>
            <div className={logoStyles.Logo}>
                <img className={logoStyles.Logo_rectangle} src={logoImage} alt="header logo"/>
                <img className={logoStyles.Logo_round} src={logoRound} alt="logo round"/>
            </div>
        </div>
      )
    }
}

export default Logo